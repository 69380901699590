import { useState } from 'react';
import { getRequest } from '../../../requests';
import { useAuthHeader, useAuthUser } from 'react-auth-kit';

export const usePayment = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const authHeader = useAuthHeader();
  const [tokenType, token] = authHeader().split(' ');
  const auth = useAuthUser();

  const makePayment = async ({
    name,
    email,
    plan,
    interval,
    card_id,
    account_id,
    coupon,
    cardToken
  }: {
    name: string;
    interval: string;
    email: string;
    card_id?: string;
    account_id?: string | number;
    coupon: string;
    plan: number;
    cardToken?: string;
  }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.createCustomer',
          email: auth() && auth()?.email,
          userEmail: email,
          name,
          plan,
          interval,
          card_id,
          period: localStorage.getItem('period') || 'month',
          trial: localStorage.getItem('trial') || '14',
          coupon,
          cardToken,
          account_id,
          token: token,
          format: 'json'
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (error: any) {
      setLoading(false);
      setErrorMessage('Error creating Subscription user');
    }
  };

  const confirmPayment = async (subscriptionId: string, is_paid: string) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.confirmPayment',
          subscriptionId,
          token: token,
          is_paid,
          format: 'json'
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage('Error confirming Subscription payment');
    }
  };

  const addCardToSubscription = async (subscriptionId: string) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.subscriptionAddCard',
          subscriptionId,
          token: token,
          format: 'json'
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.msg || response.data.message);
        }
        setLoading(false);
        return response.data;
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage('Error adding card to Subscription');
    }
  };

  const addPaymentCard = async ({
    name,
    number,
    expMonth,
    expYear,
    cvc,
    country,
    city,
    addressOne,
    addressTwo,
    customerId,
    subscriptionId,
    isNewCustomer,
    account_id
  }: {
    name: string;
    number: string;
    expMonth: string;
    expYear: string;
    cvc: string;
    country: string;
    city: string;
    addressOne: string;
    addressTwo: string;
    customerId?: string;
    subscriptionId?: string;
    isNewCustomer?: boolean;
    account_id?: number | null;
  }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.addCard',
          card_name: name,
          number,
          cvc,
          month: expMonth,
          year: expYear,
          address_1: addressOne,
          address_2: addressTwo,
          city,
          country,
          customer_id: customerId,
          isNewCustomer,
          subscriptionId,
          token: token,
          format: 'json',
          account_id
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          setErrorMessage(response.data.msg || response.data.message);
          setLoading(false);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage('Error adding a payment card');
    }
  };

  const setCardAsDefault = async ({
    cardId,
    customerId
  }: {
    cardId: string;
    customerId: string;
  }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.setCardAsDefault',
          card_id: cardId,
          customer_id: customerId,
          token: token,
          format: 'json'
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          setErrorMessage(response.data.msg || response.data.message);
          setLoading(false);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage('Error setting payment card as default');
    }
  };

  const abandonCart = async ({ plan }: { plan: number }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.abandonCart',
          plan,
          token: token,
          format: 'json'
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          setErrorMessage(response.data.msg || response.data.message);
          setLoading(false);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage('Error processing abandonCart request');
    }
  };

  const deleteCard = async ({ cardId, customerId }: { cardId: string; customerId: string }) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await getRequest({
        url: 'index.php',
        data: {},
        params: {
          module: 'API',
          method: 'PaymentIntegration.deleteCard',
          card_id: cardId,
          customer_id: customerId,
          token: token,
          format: 'json'
        }
      });

      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.msg);
          return;
        }
        setLoading(false);
        return response.data;
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage('Error deleting payment card');
    }
  };

  return {
    addPaymentCard,
    makePayment,
    confirmPayment,
    setCardAsDefault,
    deleteCard,
    addCardToSubscription,
    abandonCart,
    errorMessage,
    loading
  };
};
