import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getRequest } from '../../../requests';
import { useUserSites } from '../../../hooks/useUserSites';
import { useSignIn } from 'react-auth-kit';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { routes } from '../../../constants/routes';
import { ORGANIZATION_ID } from '../../../constants';
import { updateAmplitudeUserId } from '../../../util/amplitudeService';

export const useLogin = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { loadUserProfile } = useUserSites();
  const navigate = useNavigate();
  const signIn = useSignIn();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { setProfile, setSelectedOrganization } = useContext(OrganizationContext);

  const login = async (email: string, password: string, noRecap?: any) => {
    setLoading(true);
    setErrorMessage(null);

    try {
      let response: any;
      if (noRecap) {
        response = await getRequest({
          url: 'index.php',
          data: {},
          params: {
            module: 'API',
            method: 'PaymentIntegration.login',
            email,
            password,
            noRecap,
            format: 'json'
          }
        });
      } else {
        if (!executeRecaptcha) {
          console.log('Execute recaptcha not yet available');
          return;
        }

        const gReCaptchaToken = await executeRecaptcha('login');
        response = await getRequest({
          url: 'index.php',
          data: {},
          params: {
            module: 'API',
            method: 'PaymentIntegration.login',
            email,
            password,
            format: 'json',
            reCaptchaToken: gReCaptchaToken
          }
        });
      }

      if (response.data) {
        if (response.data.result === 'error') {
          setLoading(false);
          setErrorMessage(response.data.message || response.data.msg);
          return;
        } else if (response.data.token) {
          const profileResponse = await loadUserProfile({
            auth_token: response.data.token,
            email: response.data.email
          });
          
          if (profileResponse) {
            updateAmplitudeUserId(profileResponse.profile_details.user_id);
            setProfile(profileResponse);
            localStorage.setItem(ORGANIZATION_ID, profileResponse.organizations[0].account_id);

            if (profileResponse.subscription_details.subscription.length === 0) {
              signIn({
                token: response.data.token,
                expiresIn: 720,
                authState: {
                  ...profileResponse.profile_details,
                  hash: response.data.hash
                },
                tokenType: 'Bearer'
              });
              navigate(routes.REDIRECTION); 
              return;
            } else if (profileResponse.profile_details.super_user === 1) {
              signIn({
                token: response.data.token,
                expiresIn: 720,
                authState: {
                  ...profileResponse.profile_details,
                  hash: response.data.hash
                },
                tokenType: 'Bearer'
              });
            } else if (profileResponse.profile_details.account_holder === 1) {
              signIn({
                token: response.data.token,
                expiresIn: 720,
                authState: {
                  ...profileResponse.profile_details,
                  hash: response.data.hash
                },
                tokenType: 'Bearer'
              });
              navigate(routes.WEBSITES);
            } else {
              signIn({
                token: response.data.token,
                expiresIn: 720,
                authState: {
                  ...profileResponse.profile_details,
                  hash: response.data.hash
                },
                tokenType: 'Bearer'
              });
              navigate(routes.DASHBOARD);
            }
          }
          setLoading(false);
        } else if (!response.data.token && response.data.two_factor_setup) {
          setLoading(false);
          return response.data;
        } else {
          setLoading(false);
          setErrorMessage('Error processing request');
        }
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (error.response?.data?.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('Error connecting to server');
      }
    }
  };

  return { login, errorMessage, loading };
};