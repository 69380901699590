// CountdownRedirect.tsx
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../constants/routes';
import { useEffect } from 'react';

export default function CountdownRedirect() {
  const navigate = useNavigate();

  useEffect(() => {
    window.history.replaceState(null, '', window.location.pathname);
    window.history.pushState(null, '', window.location.pathname);

    // Handle any attempt to go back
    const handlePopState = () => {
      window.history.pushState(null, '', window.location.pathname);
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const handleBackToLogin = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = '/login/v5637true';
  };

  const handleGoToPlans = () => {
    window.location.href = 'https://www.heatmap.com/pricing';
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50">
      <div className="max-w-md p-8 bg-white rounded-lg">
        <h1 className="text-lg font-bold text-center mb-4">No Active Subscription</h1>
        <p className="text-gray-600 text-center mb-6 text-sm">
          You dont have any subscription plan. Please choose an option below:
        </p>
        <div className="flex flex-col space-y-4">
          <button 
            onClick={handleGoToPlans}
            className="w-full py-2.5 px-4 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-colors">
            Go to Plans
          </button>
          {/* <button 
            onClick={handleBackToLogin}
            className="w-full py-2.5 px-4 border border-gray-300 text-gray-700 rounded-lg hover:bg-gray-50 transition-colors">
            Back to Login
          </button> */}
        </div>
      </div>
    </div>
  );
}