import PrimaryButton from '../../../components/global/ui/buttons/PrimaryButton';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { FormikValues, useFormik } from 'formik';
import { useAddWebsite } from '../../../pages/main/Setup/useAddWebsite';
import React, { useContext, useState } from 'react';
import WebsiteExists from '../../../layouts/modal/WebsiteExists';
import Info from '../../../components/global/ui/alerts/Info';
import Error from '../../../components/global/ui/alerts/Error';
import { useAuthUser } from 'react-auth-kit';
import { IOnboarding } from '../../../interfaces/website';
import OnboardingProgressBar from './OnboardingProgressBar';
import { CLIENT_URL, ORGANIZATION_ID, SUBSCRIPTION_ID_KEY } from '../../../constants';
import { OrganizationContext } from '../../../contexts/OrganizationContext';
import { routes } from '../../../constants/routes';

export default function Platform({ changeStep, websites }: IOnboarding) {
  const { addWebsite, loading, errorMessage, infoMessage } = useAddWebsite();

  const [message, setMessage] = useState<string | null>(null);
  const [websiteClaimed, setWebsiteClaimed] = useState<boolean>(false);

  const navigate = useNavigate();
  const auth = useAuthUser();

  const [websiteClaimedOpen, setWebsiteClaimedOpen] = useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Website name is required'),
    url: Yup.string().required('Website URL is required'),
    isEcommerce: Yup.boolean(),
    addHeatmap: Yup.boolean()
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      url: '',
      subscriptionId: localStorage && localStorage.getItem(SUBSCRIPTION_ID_KEY)
    },
    validationSchema,
    onSubmit: async (values) => handleAddWebsite(values)
  });

  const { fetchOrganizationData } = useContext(OrganizationContext);

  const handleAddWebsite = async (values: FormikValues) => {
    setMessage(null);
    setWebsiteClaimed(false);
  
    const response = await addWebsite({
      name: values.name,
      url: values.url,
      subscriptionId: localStorage.getItem(SUBSCRIPTION_ID_KEY) as string
    });
  
    if (response?.data) {
      if (
        response.data.msg == 'Site Already Exists' &&
        websites?.filter((web) => web.idsite === response.data.siteId.idsite).length === 0
      ) {
        setWebsiteClaimed(true);
        setWebsiteClaimedOpen(true);
      } else if (
        response.data.msg == 'Site Already Exists' &&
        websites?.filter((web: any) => web.idsite === response.data.siteId.idsite)[0].website
          ?.verified === 0
      ) {
        setMessage('You have already added this website. Verification needed!');
  
        // Send data to pageinsight endpoint
        try {
          await fetch('https://dashboard.heatmap.com/pageinsight/v1/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              url: response.data.url,
              idsite: response.data.siteId.idsite
            })
          });
        } catch (error) {
          console.error('Error sending data to pageinsight:', error);
        }
  
        setTimeout(() => {
          localStorage.setItem(CLIENT_URL, response.data.url);
          localStorage.removeItem(SUBSCRIPTION_ID_KEY);
          navigate(
            `${routes.SETUP}?step=2&siteId=${
              response?.data.siteId.idsite
            }&url=${localStorage.getItem(CLIENT_URL)}`
          );
          changeStep(2);
        }, 2000);
      } else if (
        response.data.msg == 'Site Already Exists' &&
        websites?.filter((web: any) => web.idsite === response.data.siteId.idsite)[0].website
          ?.verified === 1
      ) {
        setMessage('You have already added and verified this website!');
        setTimeout(() => {
          navigate(routes.WEBSITES);
        }, 2000);
      } else if (response?.data.site) {
        // Send data to pageinsight endpoint
        try {
          await fetch('https://dashboard.heatmap.com/pageinsight/v1/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              url: response.data.url,
              idsite: response.data.site
            })
          });
        } catch (error) {
          console.error('Error sending data to pageinsight:', error);
        }
  
        localStorage.setItem(CLIENT_URL, response.data.url);
        localStorage.removeItem(SUBSCRIPTION_ID_KEY);
        fetchOrganizationData(localStorage.getItem(ORGANIZATION_ID) as string);
        navigate(
          `${routes.SETUP}?step=2&siteId=${response?.data.site}&url=${localStorage.getItem(
            CLIENT_URL
          )}`
        );
        changeStep(2);
      } else if (response?.data.siteId) {
        // Send data to pageinsight endpoint
        try {
          await fetch('https://dashboard.heatmap.com/pageinsight/v1/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              url: response.data.url,
              idsite: response.data.siteId.idsite
            })
          });
        } catch (error) {
          console.error('Error sending data to pageinsight:', error);
        }
  
        localStorage.setItem(CLIENT_URL, response.data.url);
        localStorage.removeItem(SUBSCRIPTION_ID_KEY);
        navigate(
          `${routes.SETUP}?step=2&siteId=${response?.data.siteId.idsite}&url=${localStorage.getItem(
            CLIENT_URL
          )}`
        );
        changeStep(2);
      }
    }
  };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        formik.handleSubmit();
      }}
      className="lg:w-1/2 w-full">
      {/*Alert users if the website they want to add has been added and verified by a different user*/}
      {websiteClaimed && websiteClaimedOpen && (
        <WebsiteExists
          message={`This site ${formik.values.url} has already been added by another user. Please contact Heatmap.com Team.`}
          closeModal={() => setWebsiteClaimedOpen(false)}
        />
      )}

      <OnboardingProgressBar step={1} />

      <div className="my-8 text-align-center">
        {auth() && auth()?.newUser ? (
          <p className="text-2xl lg:text-4xl font-bold text-black mb-2">
            {'Login to \n heatmap.com!'}
          </p>
        ) : (
          <p className="text-2xl lg:text-2xl font-semibold text-black mb-2">
            {'Where do you want to install heatmap.com?'}
          </p>
        )}
      </div>

      <div className="my-3 w-full">
        {/*<p className="text-sm text-black mb-1">Where do you want to install heatmap.com?</p>*/}
        <div className={'grid gap-3 mb-10'}>
          <div>
            <label className={'text-sm'}>Website Name</label>
            <input
              name={'name'}
              value={formik.values.name}
              onChange={formik.handleChange}
              placeholder={'My Site'}
              className={'w-full rounded border border-gray-300 mt-2 bg-transparent outline-0 p-3'}
            />
            <p className={'text-sm text-red-500'}>{formik.touched.name && formik.errors.name}</p>
          </div>
          <div>
            <label className={'text-sm'}>URL</label>
            <div className="w-full rounded border border-gray-300 flex items-center mb-1 mt-2">
              <img className="pl-3 py-2" src="/assets/url.svg" alt="" />
              <input
                className="bg-transparent w-full outline-none focus:outline-none p-3"
                type="text"
                name={'url'}
                value={formik.values.url}
                onChange={formik.handleChange}
                placeholder="www.heatmap.com"
              />
            </div>
            <p className="text-sm text-black">Enter a valid URL</p>
            <p className={'text-sm text-red-500'}>{formik.touched.url && formik.errors.url}</p>
          </div>
        </div>
      </div>
       
      <PrimaryButton
        disabled={loading}
        title={loading ? 'Adding website...' : 'Next'}
        handler={() => formik.handleSubmit()}
      />
      {message && (
        <div className={'bg-blue-100 text-blue-600 p-3 w-full mt-2 rounded-lg'}>
          <p className={'text-sm'}>{message}</p>
        </div>
      )}
      {errorMessage && <Error value={errorMessage} />}
      {infoMessage && <Info value={infoMessage} />}

      <p className="text-sm mt-4 text-center text-[#008160]">
        <Link to={routes.DASHBOARD} className="text-[#008160] hover:text-emerald-600 w-full font-medium">
          Do it later
        </Link>
      </p>
    </form>
  );
}
